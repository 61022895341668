import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Toast from './layout/Toast'
// components
import LoadingPage from './widgets/LoadingPage'
// styles
import '../styles/main.scss'

const PlaygroundPage = Loadable({loader: () => import(/* webpackChunkName: "playground" */ './pages/playground/PlaygroundPage'), loading: LoadingPage})
const LoginPage = Loadable({loader: () => import(/* webpackChunkName: "login" */ './pages/login/Login'), loading: LoadingPage})
const LogoutPage = Loadable({loader: () => import(/* webpackChunkName: "logout" */ './pages/login/Logout'), loading: LoadingPage})
const ForgotPasswordPage = Loadable({loader: () => import(/* webpackChunkName: "forgot-password" */ './pages/login/ForgotPassword'), loading: LoadingPage})
const Main = Loadable({loader: () => import(/* webpackChunkName: "main-component" */ './Main'), loading: LoadingPage})

class MainRouter extends Component {

  state = {
    loading: true,
  }

  componentDidMount() {
    this.setState({ loading: false })
    document.getElementById("body").classList.remove('unresolved')
  }

  render() {

    const { loading } = this.state

    if( loading ) {
      return null
    }

    return (
      <>
        <Switch>
          <Route exact path='/playground' component={PlaygroundPage}/>
          <Route exact path='/login' component={LoginPage}/>
          <Route exact path='/logout' component={LogoutPage}/>
          <Route exact path='/forgot-password' component={ForgotPasswordPage}/>
          <Route path='*' component={Main}/>
        </Switch>
        <Toast/>
      </>
    );
  }
}

export default MainRouter
