import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { responsiveStoreEnhancer } from 'redux-responsive'
import RootReducer from './reducers/RootReducer'
// import { offlineRequestsDiscard } from 'middlewares/OfflineRequestsDiscard'
import { verifyLogged } from './middlewares/VerifyLogged'
import { bootstrap } from './middlewares/Bootstrap'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  RootReducer,
  composeEnhancer(
    applyMiddleware(
      thunk,
      verifyLogged,
      bootstrap,
    ),
    responsiveStoreEnhancer
  ),
)

export default store
