// third party
import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4'
import 'regenerator-runtime/runtime' // necessário para funcionar o async/await em prod
// events
import WindowEvents from './events/WindowEvents'
// componets
import MainRouter from './components/MainRouter'
import * as serviceWorker from './serviceWorker'
import store from './configureStore' // Importe o store configurado

let history = createBrowserHistory()

// expoe globalemente
window.router = history

// inicializa google analytics
// ReactGA.initialize('UA-118255894-1')
ReactGA.initialize("G-LDVYRV28X1")

// atualiza rota no google analytics
history.listen(location =>  {
  // ReactGA.pageview(location.pathname)
  ReactGA.send('pageview')
  ReactGA.send({ hitType: "pageview", page: location.pathname })
})

WindowEvents(store.dispatch, store.getState)

render(
  <Provider store={store}>
    <Router history={history}>
      <MainRouter />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()
